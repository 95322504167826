import React from 'react'
import styled from 'styled-components'

import axios from 'axios'
import {Formik, withFormik, getIn, Field} from 'formik'
import * as Yup from 'yup'
import Recaptcha from 'react-recaptcha'
import {toast} from 'react-toastify'
import Toastify from './Toastify'
import useSSR from 'use-ssr'

import {colors, font, fontfamily} from '../styles/variables'

const {isBrowser} = useSSR()

const Form = styled.form`
  display: block;
  margin: 2rem 1.6rem;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`
const Label = styled.label`
  font-size: 1.6rem;
  font-family: ${fontfamily.jaRounded};
  color: ${colors.black};
  margin-bottom: 0.4rem;
  font-weight: 500;
`

const Small = styled.small`
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0 0 0 0.8rem;
  color: ${colors.lightNavy};
`

const Input = styled(props => <Field {...props} />)`
  padding: 0.6rem 0.4rem;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid ${colors.lightNavy};
  letter-spacing: 0.05rem;
  font-size: 1.6rem;
  &.is-invalid{
    border-color: ${colors.red};
  }
`

const List = styled.ul`
  display: block;
`

const Item = styled.li`
  display: block;
  font-size: 1.6rem;
  margin: 0 0 0.8rem 0;
`

const Checkbox = styled.input`
  transform: scale(1.2);
  margin: 0 0.8rem 1.8rem 0;
`

const Textarea = styled.textarea`
  padding: 0.6rem 1.2rem;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid ${colors.lightNavy};
  letter-spacing: 0.05rem;
  font-size: 1.6rem;
  min-height: 14rem;
  &.is-invalid{
    border-color: ${colors.red};
  }
`

const InvalidFeedbck = styled.span`
  color: ${colors.red};
  font-size: 1.4rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
`

const Button = styled.button`
  font-family: ${fontfamily.jaRounded};
  font-size: 1.6rem;
  display: block;
  border-radius: 100px;
  border: 2px ${colors.blue} solid;
  color: ${colors.blue};
  text-decoration: none;
  text-align: center;
  width: auto;
  padding: 0.6rem 2.4rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  cursor: pointer;
  &:disabled{
    color: ${colors.lightNavy};
    border-color: ${colors.lightNavy};
    box-shadow: none;
  }
`

const Message = styled.div`
  font-size: 1.6rem;
`

let recaptchaInstance

const executeCaptcha = () => {
  recaptchaInstance.execute()
}

const resetRecaptcha = () => {
  recaptchaInstance.reset()
}

const ErrorMessage = ({name}) => (
  <Field
    name={name}
    render={({form}) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name)
      return touch && error ? <InvalidFeedbck>{error}</InvalidFeedbck> : null
    }}
  />
)

const notifySuccess = () => toast.success('送信完了。\n折り返しご連絡いたしますのでしばらくお待ちください。', {containerId: 'Success'})
const notifyError = () => toast.error('送信に失敗しました。\nページを再読込してから、もう一度送信してください。', {containerId: 'Error'})

const InnerForm = ({
  values,
  touched,
  status,
  errors,
  dirty,
  setStatus,
  setFieldValue,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
  handleReset
}) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup>
      <Label>氏名 <Small>(必須)</Small></Label>
      <Input
        name='name'
        type='text'
        className={`form-control ${errors.name && touched.name && 'is-invalid'}`}
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage name='name' />
    </FormGroup>

    <FormGroup>
      <Label>メールアドレス<Small>(必須)</Small></Label>
      <Input
        name='email'
        type='email'
        className={`form-control ${errors.email && touched.email && 'is-invalid'}`}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage name='email' />
    </FormGroup>

    <FormGroup>
      <Label>メッセージ<Small>(必須)</Small></Label>
      <Textarea
        name='message'
        type='textarea'
        className={`form-control ${errors.message && touched.message && 'is-invalid'}`}
        value={values.message}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage name='message' />
    </FormGroup>

    <FormGroup>
      {isBrowser &&
      <>
        <Recaptcha
          ref={(e) => { recaptchaInstance = e }}
          size='invisible'
          sitekey='6LdkcsEUAAAAAIAXuMITlUvoJ35-13alVA29ckCS'
          verifyCallback={(response) => {
            setFieldValue('recaptcha', response)
            handleSubmit()
          }}
        />
        <ErrorMessage name='recaptcha' />
      </>
      }
    </FormGroup>

    <ButtonWrapper>
      <Button type='button'
        onClick={executeCaptcha}
        className='btn btn-outline-primary'
        disabled={isSubmitting || (status && status.state === 'Success')}
      >
        {isSubmitting ? '送信中...' : (status && status.state === 'Success') ? '送信済み' : '送信する'}
      </Button>
    </ButtonWrapper>

    <Toastify
      enableMultiContainer
      containerId={'Success'}
      position='top-right'
      autoClose={8000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      pauseOnHover
      className='myToast'
    />
    <Toastify
      enableMultiContainer
      containerId={'Error'}
      position='top-right'
      autoClose={8000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      pauseOnHover
      className='myToast'
    />

  </Form>
)

const ContactForm = withFormik({
  mapPropsToValues: () => ({
    name: '',
    email: '',
    message: '',
    recaptcha: ''
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required('氏名を入力してください'),
    email: Yup.string().email('無効なメールアドレスです。入力内容をご確認ください。').required('メールアドレスを入力してください'),
    message: Yup.string().required('メッセージを入力してください'),
    recaptcha: Yup.string().required('ロボットの可能性があるため送信できません。再送信してください。')
  }),

  handleSubmit:
   (values, {
     setSubmitting, resetForm, setStatus
   }) => {
     setStatus({state: 'Submitting'})
     setTimeout(() => {
       axios.request({
         method: 'POST',
         baseURL: '/api/message/contact',
         headers: {
           'Content-Type': 'application/json; charset=UTF-8'
         },
         data: JSON.stringify({
           name: values.name,
           email: values.email,
           message: values.message
         })
       })
         .then(() => {
           //  resetForm()
           notifySuccess()
           setSubmitting(false)
           setStatus({state: 'Success'})
         }).catch(() => {
           notifyError()
           setSubmitting(false)
           setStatus({state: 'Error'})
         })
     }, 1200)
   }
})(InnerForm)

export default ContactForm
