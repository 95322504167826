import React from 'react'
import styled from 'styled-components'

import {buildImageObj, cn, toPlainText} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

import {colors, font, fontfamily} from '../styles/variables'

import Heading from './heading'
import Button from './button'
import ContactFrom from './contact-form'
import {Link} from 'gatsby'

const Wrapper = styled.section`
  display: block;
  padding: 4rem 0;
  @media (min-width: 768px) {
    margin: 0 auto;
    max-width: 680px;
  }
`

const ImageWrapper = styled.div`
  display: block;
  margin: 0 2.4rem 4rem 2.4rem;
`

const Image = styled.img`
  display: block;
  border-radius: 5px;
`

const Texts = styled.div`
  margin: 0 2.4rem 4rem 2.4rem;
`

const Text = styled.p`

`

const SubHeading = styled.h2`
  font-size: 1.8rem;
  font-family: ${fontfamily.jaRounded};
  margin: 0 0 2rem 0;
`

const StyledLink = styled(props => <Link {...props} />)`
  font-size: 1.6rem;
  color: ${colors.blue};
  text-decoration: none;
 `

const Form = styled.div`
  display: block;
  margin-bottom: 8rem;
 `

class Contact extends React.Component {
  render () {
    return (
      <Wrapper>
        <Heading title='お問い合わせ' titleEn='Contact' />
        <Texts>
          <SubHeading>フォームによるお問い合わせ</SubHeading>
          <p>まずはFAQ(よくある質問をご覧ください。それでも解決しない場合は、下記フォームからお問い合わせ下さい
            <Link to='/faq'>FAQ(よくある質問)ページへ</Link>
          </p>
          <p>
          入園・見学については<Link to='/application/'>入園・見学お問い合わせ</Link>からお問い合わせください。</p>
          <p>
          採用に関するご連絡は<Link to='/careers/'>採用情報</Link>をご確認ください。
          </p>
          <PortableText blocks={this.props._rawBody} />
        </Texts>
        <Form>
          <ContactFrom />
        </Form>
        <Texts>
          <SubHeading>お電話・FAXでのお問い合わせ</SubHeading>
          <Text>電話: <a href='tel:201-947-4832'>(201)947-4832</a><br />
          FAX: (201)944-3680
          </Text>
        </Texts>
        <Texts>
          <SubHeading>郵送によるお問い合わせ</SubHeading>
          <Text>
            Japanese Childrens Society<br />
            8 West Bayview Avenue,<br />
            Englewood Cliffs, NJ 07632
          </Text>
        </Texts>

      </Wrapper>
    )
  }
}
export default Contact
